import "../createBlog/style.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { fetchBlog, setBlog } from "../../store/blogSlice";
import Editor from "../editor";
import loaderEvent from "../../component/loader/loaderEmitter";
import { toast } from "react-toastify";
import CustomScrollbar from "../../component/scrollBar";

const EditBlogContent = () => {
  const [blogContent, setBlogContent] = useState("");
  const [blogContentCopy, setBlogContentCopy] = useState("");
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const blogState = useSelector((state) => state.blog);
  const dispatch = useDispatch();

  const { blog_id } = useParams();
  const inputPost = (e) => {
    console.log(e.target.value);
    setBlogContentCopy(e.target.value);
    if (!change) {
      setChange(true);
    }
  };
  console.log(change);
  useEffect(() => {
    if (blogState.blog_id !== blog_id) {
      dispatch(fetchBlog(blog_id));
    }
  }, [blogState.blog_id, blog_id, dispatch]);
  useEffect(() => {
    setBlogContentCopy(blogState.blog_content);
    setBlogContent(blogState.blog_content);
  }, [blogState.blog_content, blogState.blog_id]);

  const submit = async () => {
    loaderEvent.emit("showLoader");
    const parser = new DOMParser();
    const doc = parser.parseFromString(blogContentCopy, "text/html");

    const imgElements = doc.querySelectorAll("img");
    console.log(doc, blogContentCopy);
    const requests = Array.from(imgElements).map(async function (img) {
      if (img.src.startsWith("data:image")) {
        let response = await fetch("/api/v2/image-upload", {
          method: "post",
          body: JSON.stringify({
            blog_id: blog_id,
            blog_image: img.src,
          }),
        });
        response = await response.json();
        img.src = response.data.image;
        return Promise.resolve();
      }
      return Promise.resolve();
    });
    Promise.all(requests).then(function () {
      const modifiedHtmlString = doc.body.innerHTML;
      console.log(modifiedHtmlString);
      const textLength = doc.body.innerText.split(" ").length;
      const readingTime = (textLength / 200).toFixed() || 1;
      setBlogContent(modifiedHtmlString);
      fetch("/api/v2/update-post-content", {
        method: "POST",
        body: JSON.stringify({
          blog_id: blog_id,
          reading_time: readingTime,
          blog_content: modifiedHtmlString,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 1) {
            toast.success("Blog content updated");
            // navigate("/blogs")
          } else {
            toast.error(response.message);
          }
        })
        .finally(() => {
          loaderEvent.emit("hideLoader");
        });
    });
  };
  const publishBlog = (published) => {
    loaderEvent.emit("showLoader");
    fetch("/api/v2/publish-blog", {
      method: "POST",
      body: JSON.stringify({ blog_id: blog_id, published: published }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          loaderEvent.emit("hideLoader");
          toast.success(`Blog ${published ? "Published" : "Unpublished"}`);
          dispatch(setBlog({ ...blogState, published: published }));
          // navigate("/blogs")
        }
      });
  };
  return (
    <>
      <div className="row">
        <div className="col" style={{ overflow: "hidden" }}>
          {/* {blogContent && ( */}
          <Editor initialValue={blogContent} onChange={(e) => inputPost(e)} />
          {/* )} */}
          <div className="edit-button-group-height" />
          <div className="col-12 edit-button-group">
            <div className="row">
              <div className="col-auto">
                <button className="" disabled={!change} onClick={submit}>
                  Save
                </button>
              </div>
              <div className="col-auto">
                <button
                  className=""
                  disabled={!change}
                  onClick={() => {
                    window.open(
                      "https://nuctro.com/preview/" + blog_id,
                      "_blank"
                    );
                  }}
                >
                  Preview
                </button>
              </div>
              <div className="col-auto">
                {!blogState.published ? (
                  <button
                    className=""
                    disabled={!change}
                    onClick={() => publishBlog(true)}
                  >
                    Publish
                  </button>
                ) : (
                  <button
                    className=""
                    disabled={!change}
                    onClick={() => publishBlog(false)}
                  >
                    Un publish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-auto card-block sidebar-post-container ">
          <CustomScrollbar>
            <div className="sidebar-post-body">
              <div className="row  g-2 mb-2">
                <div className="col-auto">
                  <button
                    className="blog-edit-button save-blog-button"
                    disabled={!change}
                    onClick={submit}
                  >
                    Save
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    className="blog-edit-button create-blog-button"
                    disabled={!change}
                    onClick={() => {
                      window.open(
                        "https://nuctro.com/preview/" + blog_id,
                        "_blank"
                      );
                    }}
                  >
                    Preview
                  </button>
                </div>
                <div className="col-auto">
                  {!blogState.published ? (
                    <button
                      className="blog-edit-button publish-blog-button"
                      disabled={!change}
                      onClick={() => publishBlog(true)}
                    >
                      Publish
                    </button>
                  ) : (
                    <button
                      className="blog-edit-button unpublish-blog-button"
                      disabled={!change}
                      onClick={() => publishBlog(false)}
                    >
                      Un publish
                    </button>
                  )}
                </div>
              </div>
              <p>
                <b>Title</b>
              </p>
              <p className="title">{blogState.blog_title}</p>
              <p>
                <b>Description</b>
              </p>
              <p>{blogState.blog_description}</p>
              <p>
                <b>Image</b>
              </p>
              <img src={blogState.blog_image} />
              <p>
                <b>Meta tag</b>
              </p>
              <p>{blogState.meta_tag}</p>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </>
  );
};
export default EditBlogContent;
