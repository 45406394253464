import { $applyNodeReplacement, DecoratorNode } from "lexical";
import { Suspense } from "react";
import { useCallback, useEffect, useRef } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import {
  $createParagraphNode,
  $getNodeByKey,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
  KEY_BACKSPACE_COMMAND,
} from "lexical";

export default function YouTubeComponent({ videoId, nodeKey }) {
  const videoRef = useRef(null);
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();

  const onClick = useCallback(
    (payload) => {
      const event = payload;
      if (event.target === videoRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }
      return false;
    },
    [isSelected, setSelected, clearSelection]
  );

  const handleEnterCommand = useCallback(() => {
    if (isSelected) {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (node) {
          // Create a new paragraph node and insert it after the YouTube node
          const paragraphNode = $createParagraphNode();
          node.insertAfter(paragraphNode);
          paragraphNode.select();
        }
      });
      return true;
    }
    return false;
  }, [editor, isSelected, nodeKey]);

  const handleBackspaceCommand = useCallback(() => {
    if (isSelected) {
      // Remove the node when BACKSPACE is pressed while the component is selected
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (node) {
          node.remove();
        }
      });
      return true;
    }
    return false;
  }, [editor, isSelected, nodeKey]);

  useEffect(() => {
    const unregisterClick = editor.registerCommand(
      CLICK_COMMAND,
      onClick,
      COMMAND_PRIORITY_LOW
    );
    const unregisterEnter = editor.registerCommand(
      KEY_ENTER_COMMAND,
      handleEnterCommand,
      COMMAND_PRIORITY_LOW
    );
    const unregisterBackspace = editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      handleBackspaceCommand,
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregisterClick();
      unregisterEnter();
      unregisterBackspace();
    };
  }, [editor, onClick, handleEnterCommand, handleBackspaceCommand]);

  const videoUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      ref={videoRef}
      // width="100%"
      // height="315"
      src={videoUrl}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}

function VideoComponent({ src, className }) {
  const videoSrc = `https://www.youtube.com/embed/${src}`;

  return (
    <iframe
      className={className}
      src={videoSrc}
      width="100%" // Make iframe responsive
      height="100%" // Maintain aspect ratio based on width
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{
        position: "relative",
        width: "100%", // Set width to 100% to make iframe responsive
        height: "100%", // Height adjusts based on width to maintain aspect ratio
        aspectRatio: "16 / 9", // Using aspectRatio for automatic scaling
      }}
    />
  );
}

export class YouTubeNode extends DecoratorNode {
  __src;

  static getType() {
    return "video";
  }

  static clone(node) {
    return new YouTubeNode(node.__src, node.__format, node.__key);
  }

  exportJSON() {
    return {
      src: this.getSrc(),
      type: "video",
      version: 1,
    };
  }

  handleKeyDown(event, editor) {
    const { key } = event;

    // If the Enter key is pressed, create a new line after the video
    if (key === "Enter") {
      editor.insertNewBlock();
      return true; // prevent default action for Enter (new line)
    }

    // If the Backspace key is pressed, remove the node
    if (key === "Backspace") {
      editor.removeNode(this.getKey()); // remove the current node
      return true; // prevent default Backspace behavior
    }

    return false; // let Lexical handle other key events
  }

  createDOM() {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", `https://www.youtube.com/embed/${this.__src}`);
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "100%");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute(
      "allow",
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    );
    iframe.setAttribute("allowfullscreen", "true");

    iframe.style.position = "relative";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.aspectRatio = "16 / 9"; // Maintain aspect ratio

    return iframe;
  }

  updateDOM() {
    return false;
  }

  exportDOM() {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", `https://www.youtube.com/embed/${this.__src}`);
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "100%");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute(
      "allow",
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    );
    iframe.setAttribute("allowfullscreen", "true");

    iframe.style.position = "relative";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.aspectRatio = "16 / 9"; // Maintain aspect ratio

    return { element: iframe };
  }

  static importDOM() {
    return {
      iframe: (node) => {
        if (!node.src.includes("youtube.com/embed")) {
          return null;
        }
        return {
          conversion: (domNode) => {
            const src = domNode.src.split("/embed/")[1];
            return { node: $createYouTubeNode(src) };
          },
          priority: 1,
        };
      },
    };
  }

  constructor(src, key) {
    super(key);
    this.__src = src;
  }

  getSrc() {
    return this.__src;
  }

  decorate(_editor, config) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };

    // Directly render VideoComponent without wrapping it in BlockWithAlignableContents
    return (
      <Suspense fallback={null}>
        <YouTubeComponent src={this.__src} 
          nodeKey={this.getKey()}
      
        className={className} />
      </Suspense>
    );
  }

  isInline() {
    return false;
  }
}

// Simplified factory function only requiring `src`
export function $createYouTubeNode(src) {
  return $applyNodeReplacement(new YouTubeNode(src));
}

export function $isYouTubeNode(node) {
  return node instanceof YouTubeNode;
}
