import "../createBlog/style.css";

import { useCallback, useDeferredValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ImageEditor from "../../component/imageEditor";
import { fetchBlog, setBlog } from "../../store/blogSlice";
import { MultiSelectInput } from "../../component/multSelectInput";
import { categoryList } from "../../utils/categoryList";
import CustomScrollbar from "../../component/scrollBar";

const CreateBlog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blog = useSelector((state) => state.blog);
  const dispatch = useDispatch();
  const [changed, setChanged] = useState(false);
  const [post, setPost] = useState({
    blog_title: "",
    blog_description: "",
    blog_image: "",
    meta_keywords: "",
    blog_url: "",
    blog_category: [],
    published: null,
  });
  console.log(blog);
  const deferedBlog = useDeferredValue(post);

  const [imageToEdit, setImageToEdit] = useState(null);
  const { blog_id } = useParams();
  const inputPost = useCallback((e) => {
    setChanged(true);
    setPost({ ...post, [e.target.name]: e.target.value });
  });
  const handleImageChange = (event) => {
    setChanged(true);
    setImageToEdit(event.target.files[0]);
    setPost({ ...post, blog_image: event.target.files[0] });
  };
  useEffect(() => {
    if (blog.blog_id !== blog_id) {
      dispatch(fetchBlog(blog_id));
    }
  }, [blog_id]);
  useEffect(() => {
    setPost({
      blog_title: blog.blog_title,
      blog_description: blog.blog_description,
      blog_image: blog.blog_image,
      meta_keywords: blog.meta_keywords,
      blog_url: blog.blog_url,
      blog_category: blog.blog_category || [],
      published: blog.published,
    });
  }, [blog]);

  const submit = () => {
    fetch("/api/v2/update-post", {
      method: "POST",
      body: JSON.stringify({ ...post, blog_id: blog_id }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          dispatch(setBlog(post));
          setChanged(false);

          // navigate("/blogs")
        }
      });
  };

  const publishBlog = (published) => {
    fetch("/api/v2/publish-blog", {
      method: "POST",
      body: JSON.stringify({ blog_id: blog_id, published: published }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          dispatch(setBlog({ ...post, published: published }));
          setChanged(false);
        }
      });
  };

  const saveImage = (img) => {
    setPost({ ...post, blog_image: img });
    setImageToEdit(null);
  };

  return (
    <>
      {imageToEdit && (
        <ImageEditor imageToEdit={imageToEdit} updateImage={saveImage} />
      )}
      {!blog.isLoading && (
        <div className="row">
          <div className="col" style={{ overflow: "hidden" }}>
            {
              <div className="card card-block">
                <div className="card-header card-block-header">
                  <div className="col-12">
                    <h5>Edit Blog</h5>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Title of blog
                          </label>
                          <input
                            onChange={(e) => inputPost(e)}
                            value={post["blog_title"]}
                            type="text"
                            name="blog_title"
                            className="form-control"
                            id=""
                            aria-describedby=""
                            placeholder="Enter title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Description of blog
                          </label>
                          <textarea
                            onChange={inputPost}
                            value={post["blog_description"]}
                            type="text"
                            name="blog_description"
                            className="form-control"
                            id=""
                            aria-describedby=""
                            placeholder="Enter description"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-auto">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Image thumbnail
                            </label>
                            <div className="image-thumbnail">
                              {post.blog_image ? (
                                <img src={post.blog_image} alt="Selected" />
                              ) : (
                                "Click here or drop your image to upload"
                              )}
                              <input
                                type="file"
                                className="image-input"
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Category of blog
                                </label>
                                <MultiSelectInput
                                  options={categoryList}
                                  maxSelection={4}
                                  value={post.blog_category}
                                  onChange={(value) => {
                                    setChanged(true);
                                    setPost({ ...post, blog_category: value });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Meta tags of blog
                                  </label>
                                  <textarea
                                    onChange={inputPost}
                                    value={post["meta_keywords"]}
                                    type="text"
                                    name="meta_keywords"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Enter meta tag"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Url of blog(optional e.g name-of-blog)
                                  </label>
                                  <input
                                    onChange={inputPost}
                                    value={post["blog_url"]}
                                    name="blog_url"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="blog URL"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="edit-button-group-height" />
            <div className="col-12 edit-button-group">
              <div className="row">
                <div className="col-auto">
                  <button className="" disabled={!changed} onClick={submit}>
                    Save
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    className=""
                    disabled={changed}
                    onClick={() => {
                      //
                      navigate(`/edit-blog-content/${blog_id}`);
                    }}
                  >
                    Edit Content
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    className=""
                    onClick={() => {
                      console.log(blog_id);
                      window.open(
                        "https://www.nuctro.com/preview/" + blog_id,
                        "_blank"
                      );
                    }}
                  >
                    Preview
                  </button>
                </div>
                <div className="col-auto">
                  {!post.published ? (
                    <button
                      className=""
                      disabled={changed}
                      onClick={() => publishBlog(true)}
                    >
                      Publish
                    </button>
                  ) : (
                    <button
                      className="blog-edit-button unpublish-blog-button"
                      disabled={changed}
                      onClick={() => publishBlog(false)}
                    >
                      Un publish
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {
            <div className="col-auto  card-block sidebar-post-container">
              <CustomScrollbar>
                <div className="sidebar-post-body">
                  <div className="row g-2 mb-2">
                    <div className="col-auto">
                      <button
                        className="blog-edit-button save-blog-button"
                        disabled={!changed}
                        onClick={submit}
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        className="blog-edit-button create-blog-button"
                        disabled={changed}
                        onClick={() => {
                          //
                          navigate(`/edit-blog-content/${blog_id}`);
                        }}
                      >
                        Edit Content
                      </button>
                    </div>
                    <div className="col-auto">
                      <button
                        className="blog-edit-button preview-blog-button"
                        onClick={() => {
                          console.log(blog_id);
                          window.open(
                            "https://www.nuctro.com/preview/" + blog_id,
                            "_blank"
                          );
                        }}
                      >
                        Preview
                      </button>
                    </div>
                    <div className="col-auto">
                      {!post.published ? (
                        <button
                          className="blog-edit-button publish-blog-button"
                          disabled={changed}
                          onClick={() => publishBlog(true)}
                        >
                          Publish
                        </button>
                      ) : (
                        <button
                          className="blog-edit-button unpublish-blog-button"
                          disabled={changed}
                          onClick={() => publishBlog(false)}
                        >
                          Un publish
                        </button>
                      )}
                    </div>
                  </div>

                  <p>
                    <b>Instruction</b>
                  </p>
                  <p>
                    For better visibility in search engine please follow these
                    rules
                  </p>
                  <ul>
                    <li>
                      Create catchy and descriptive titles that include your
                      main keyword. Keep them under 60 characters to ensure they
                      display fully in search results.
                    </li>
                    <li>
                      Write brief summaries (around 160 characters) that include
                      your keyword and encourage clicks by explaining what your
                      content offers.
                    </li>
                    <li>
                      Create valuable and engaging content that satisfies users'
                      search intent. Incorporate keywords naturally throughout
                      your content.
                    </li>
                    <li>
                      Link to authoritative sources when it adds value to your
                      content. This can improve your credibility and visibility.
                    </li>
                  </ul>
                </div>
              </CustomScrollbar>
            </div>
          }
        </div>
      )}
    </>
  );
};
export default CreateBlog;
