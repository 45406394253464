import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

// import "../styles.css";
import { INSERT_YOUTUBE_COMMAND } from "./youtubePlugin";
import { useState } from "react";

export function FillURL() {
  const url = prompt("Enter the URL of the YouTube video:", "");

  const match =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);

  const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

  if (id !== null) {
    return id;
  }

  return null;
}

export default function YouTubeToolbarPlugin() {
  const [editor] = useLexicalComposerContext();

  return (
    <div className="toolbar">
      <button
        onClick={() => {
          editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, FillURL());
        }}
        className={"toolbar-item spaced "}
      >
        <span className="text">Insert YouTube Video</span>
      </button>
    </div>
  );
}
export function YoutubeUrlDialog({ activeEditor, onClose }) {
  const [url, setUrl] = useState("");
  const [error, setError] = useState(false);

  const submit = async (e) => {
    if (url) {
      try {
        const match =
          /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(
            url
          );

        const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

        if (id !== null) {
          activeEditor.dispatchCommand(INSERT_YOUTUBE_COMMAND, id);
    onClose();
        } else {
          setError(true);
        }
        return null;
        // You can use the base64Data here as needed, for example, send it to a server
      } catch (error) {
        console.error("Error:", error);
      }
    }

  };

  return (
    <>
      <div className="modal-editor-file-background" onClick={onClose} />
      <div className="modal-editor-file modal-youtube-url">
        <div className="">
          Upload your image
          <input
            type="text"
            className="input-file"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          {error && <p style={{ color: "red", margin: "5px 0 0" }}>Not a valid youtube url</p>}
          <button onClick={submit} disabled={!url || error}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
