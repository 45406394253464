import { useRef, useEffect } from "react";

const CustomScrollbar = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const verticalThumbContainerRef = useRef(null);

  const verticalThumbRef = useRef(null);
  const horizontalThumbRef = useRef(null);
  const horizontalThumbContainerRef = useRef(null);

  const isDraggingVertical = useRef(false);
  const isDraggingHorizontal = useRef(false);
  let startY, startX, startScrollTop, startScrollLeft;

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const verticalThumb = verticalThumbRef.current;
    const verticalThumbContainer = verticalThumbContainerRef.current;
    

    const horizontalThumb = horizontalThumbRef.current;
    const horizontalThumbContainer = horizontalThumbContainerRef.current;

    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = `
      [data-custom-scroll]::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
      }
    `;
    document.head.appendChild(styleSheet);
    scrollContainer.setAttribute("data-custom-scroll", "true");
    const updateThumbs = () => {
      const {
        scrollHeight,
        clientHeight,
        scrollTop,
        scrollWidth,
        clientWidth,
        scrollLeft,
      } = scrollContainer;

      const verticalThumbHeight = (clientHeight / scrollHeight) * clientHeight;
      if (verticalThumbHeight === clientHeight) {
        verticalThumbContainer.style.display = `none`;
      } else {
        verticalThumbContainer.style.display = `block`;
        verticalThumb.style.height = `${verticalThumbHeight}px`;
        verticalThumb.style.top = `${(scrollTop / scrollHeight) * clientHeight}px`;
      }

      const horizontalThumbWidth = (clientWidth / scrollWidth) * clientWidth;
      if (horizontalThumbWidth === clientWidth) {
        horizontalThumbContainer.style.display = `none`;
      } else {
        verticalThumbContainer.style.display = `block`;
        horizontalThumb.style.width = `${horizontalThumbWidth}px`;
        horizontalThumb.style.left = `${(scrollLeft / scrollWidth) * clientWidth}px`;
      }
    };

    const handleScroll = () => updateThumbs();

    const handleMouseDownVertical = (e) => {
      isDraggingVertical.current = true;
      startY = e.clientY;
      startScrollTop = scrollContainer.scrollTop;
      document.body.style.userSelect = "none";
    };

    const handleMouseMoveVertical = (e) => {
      if (!isDraggingVertical.current) return;
      const deltaY = e.clientY - startY;
      const { scrollHeight, clientHeight } = scrollContainer;
      const scrollRatio = scrollHeight / clientHeight;
      scrollContainer.scrollTop = startScrollTop + deltaY * scrollRatio;
    };

    const handleMouseDownHorizontal = (e) => {
      isDraggingHorizontal.current = true;
      startX = e.clientX;
      startScrollLeft = scrollContainer.scrollLeft;
      document.body.style.userSelect = "none";
    };

    const handleMouseMoveHorizontal = (e) => {
      if (!isDraggingHorizontal.current) return;
      const deltaX = e.clientX - startX;
      const { scrollWidth, clientWidth } = scrollContainer;
      const scrollRatio = scrollWidth / clientWidth;
      scrollContainer.scrollLeft = startScrollLeft + deltaX * scrollRatio;
    };

    const handleMouseUp = () => {
      isDraggingVertical.current = false;
      isDraggingHorizontal.current = false;
      document.body.style.userSelect = "";
    };

    scrollContainer.addEventListener("mouseenter", handleScroll);
    scrollContainer.addEventListener("scroll", handleScroll);

    verticalThumb.addEventListener("mousedown", handleMouseDownVertical);
    horizontalThumb.addEventListener("mousedown", handleMouseDownHorizontal);
    document.addEventListener("mousemove", handleMouseMoveVertical);
    document.addEventListener("mousemove", handleMouseMoveHorizontal);
    document.addEventListener("mouseup", handleMouseUp);

    // updateThumbs();

    return () => {
      scrollContainer.removeEventListener("mouseenter", handleScroll);
      scrollContainer.addEventListener("scroll", handleScroll);
      verticalThumb.removeEventListener("mousedown", handleMouseDownVertical);
      horizontalThumb.removeEventListener(
        "mousedown",
        handleMouseDownHorizontal
      );
      document.removeEventListener("mousemove", handleMouseMoveVertical);
      document.removeEventListener("mousemove", handleMouseMoveHorizontal);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <div
        ref={scrollContainerRef}
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          position: "relative",
        }}
      >
        {children}
      </div>
      <div
        ref={verticalThumbContainerRef}

        style={{
          position: "absolute",
          right: "2px",
          top: 0,
          width: "6px",
          height: "100%",
          background: "rgba(0,0,0,0.1)",
          borderRadius: "4px",
          display: "none"
        }}
      >
        <div
          ref={verticalThumbRef}
          style={{
            position: "absolute",
            width: "100%",
            background: "gray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        ></div>
      </div>
      <div
        ref={horizontalThumbContainerRef}
        style={{
          position: "absolute",
          bottom: "2px",
          left: 0,
          width: "100%",
          height: "6px",
          background: "rgba(0,0,0,0.1)",
          borderRadius: "4px",
          display: "none"
        }}
      >
        <div
          ref={horizontalThumbRef}
          style={{
            position: "absolute",
            height: "100%",
            background: "gray",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        ></div>
      </div>
    </div>
  );
};

export default CustomScrollbar;
